import React, { useEffect, useState, useMemo, useRef } from "react"
import { TableVirtuoso } from "react-virtuoso"
import api, { useRequest } from "@app/services/api"
import { useSelector } from "@app/models"
import GroupManagementHeader from "./GroupManagementHeader"
import Tooltip from "@app/components/Tooltip"
import Modal from "@app/components/Modal"
import { handleApiError } from "@app/utils"
import { transformToBooleans } from "../utils/transformToBolean"
import { useClinicPermission } from "@app/hooks"
import SettingsColumnsRenderer from "./SettingsColumnsRenderer"
import SyncSettingsTable from "./SyncSettingsTable"
import SettingsContentModal from "./SettingsContentModal/SettingsContentModal"
import TimezoneSetting from "./TimezoneSetting"
import cx from "classnames"
import css from "./GroupManagementTable.module.scss"
import AddGroupContentModal from "./AddGroupContentModal/AddGroupContentModal"
import AddOrgsToGroup from "./AddOrgsToGroup"

interface GroupData {
  groupid: number
  name: string
  abbrev: string
  jobs_count: number
  tc_integrated_jobs_count: number
  providers_count: number
  active_providers_count: number
  schedulers_count: number
  active_schedulers_count: number
  upcoming_assignments_count: string
  latest_assigned_date: string
  latest_assigned_date_draft: string
  provider_protocols_count: number
  protocols_count: number
  tigerconnect_roles_sync: boolean
  tigerconnect_roles_sync_2way: boolean

  scheduling_gap_notifications: boolean
  timezone: string
  daylight_savings: boolean
}

interface GroupSettings {
  block_scheduling: boolean
  batch_import_export: boolean
  multicalendar_report: boolean
  split_shift: boolean
  always_track_splitshift: boolean
  casting_jobs: boolean
  draft_mode: boolean
  multiple_assignments: boolean
  calendar_v2: boolean
  scheduler_edit_group_merged_daily: boolean
  tigerconnect_settings: TigerConnectSetting[] | null
  [key: string]: boolean | string | TigerConnectSetting[] | null
}

interface TigerConnectSetting {
  id: string
  groupid: number
  label: string
  organization_token: string
  integrationid: string
  tigerconnect_key: string
  tigerconnect_secret: string
  created_at: string
  updated_at: string
  two_way_sync: boolean
}

interface SettingsTableData {
  label: string
  value: string
  integrationid?: string
  two_way_sync?: boolean
}

const PAGE_SIZE = 50

const settingsMap: Record<string, string> = {
  batch_import_export: "Batch Import / Export",
  multicalendar_report: "Multicalendar Report",
  split_shift: "Split Shift",
  always_track_splitshift: "Always Track Split Shift Changes",
  block_scheduling: "Block Scheduling",
  casting_jobs: "Allow display of empty assignments",
  draft_mode: "Draft Mode",
  multiple_assignments: "Allow multiple assignments for one job",
  calendar_v2: "Calendar 2.0 (Enable only for EA clients)",
  scheduler_edit_group_merged_daily:
    "MDV Settings - Edit my Groups protocols/Timezone/Job Times",
  tigerconnect_roles_sync: "TigerConnect Roles Sync Enabled",
  scheduling_gap_notifications: "Notify schedulers of empty TigerConnect Roles",
  tigerconnect_one_to_multiple_roles_sync:
    "Enable sync from one job to multiple roles",
}

const TIGERCONNECT_SETTINGS_KEYS = [
  "tigerconnect_roles_sync",
  "scheduling_gap_notifications",
  "tigerconnect_one_to_multiple_roles_sync",
]

const FEATURE_SETTINGS_KEYS = Object.keys(settingsMap).filter(
  (key) => !TIGERCONNECT_SETTINGS_KEYS.includes(key)
)

const createSettingsColumns = (keys: string[]) => {
  const half = Math.ceil(keys.length / 2)
  return [keys.slice(0, half), keys.slice(half)]
}

const GroupManagementTable: React.FC = () => {
  const { clinicid, clinic } = useSelector((state) => state.users.currentUser)
  const { data: clinicData, mutate: refreshClinicData } = useRequest<{
    groups: GroupData[]
    total: number
    page: number
    per: number
  }>(clinicid ? [api.getGroupsByClinic, clinicid] : null)
  const [searchTerm, setSearchTerm] = useState<string>("")
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [sortBy, setSortBy] = useState<
    "name" | "latest_assigned_date" | "latest_assigned_date_draft" | null
  >(null)
  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | null>(null)

  const virtuosoRef = useRef<any>(null)
  const filteredData = useMemo(() => {
    if (!clinicData) return []
    return clinicData.groups.filter(
      (group) =>
        group.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        group.groupid.toString().includes(searchTerm)
    )
  }, [clinicData, searchTerm])
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false)
  const [selectedGroup, setSelectedGroup] = useState<GroupData | null>(null)
  const [groupSettings, setGroupSettings] = useState<GroupSettings | null>(null)
  const [settingsTableData, setSettingsTableData] = useState<
    SettingsTableData[]
  >([])
  const [editedGroupName, setEditedGroupName] = useState<string | null>(null)
  const [editedGroupAbbrev, setEditedGroupAbbrev] = useState<string | null>(
    null
  )
  const [editedTimezone, setEditedTimezone] = useState<string>(
    selectedGroup?.timezone || ""
  )
  const [daylightSavings, setDaylightSavings] = useState<boolean>(
    selectedGroup?.daylight_savings || false
  )
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [editingRowIndex, setEditingRowIndex] = useState<number | null>(null)
  const [isEditingRow, setIsEditingRow] = useState<boolean>(false)
  const [editingRowData, setEditingRowData] = useState<
    Record<number, TigerConnectSetting>
  >({})
  const [originalGroupSettings, setOriginalGroupSettings] =
    useState<GroupSettings | null>(null)
  const [showDefaultSettingsModal, setShowDefaultSettingsModal] =
    useState<boolean>(false)
  const [showAddGroupModal, setShowAddGroupModal] = useState<boolean>(false)
  const [showAddOrgModal, setShowAddOrgModal] = useState(false)
  const featureSettingsColumns = createSettingsColumns(FEATURE_SETTINGS_KEYS)
  const tigerConnectSettingsColumns = createSettingsColumns(
    TIGERCONNECT_SETTINGS_KEYS
  )
  const canEditSettings = useClinicPermission("Group Admin") ?? false
  const { currentUser } = useSelector((state) => state.users)
  const isTrainer = currentUser?.current_role?.roleid === 5
  const canAddGroup = canEditSettings || isTrainer

  const sortedData = useMemo(() => {
    if (!sortBy) {
      return filteredData
    }

    return [...filteredData].sort((a, b) => {
      if (sortBy === "name") {
        return sortOrder === "asc"
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name)
      } else {
        return sortOrder === "asc"
          ? new Date(a.latest_assigned_date).getTime() -
              new Date(b.latest_assigned_date).getTime()
          : new Date(b.latest_assigned_date).getTime() -
              new Date(a.latest_assigned_date).getTime()
      }
    })
  }, [filteredData, sortBy, sortOrder])

  const pagedData = useMemo(() => {
    const start = (currentPage - 1) * PAGE_SIZE
    const end = start + PAGE_SIZE
    return sortedData.slice(start, end)
  }, [sortedData, currentPage])

  useEffect(() => {
    setCurrentPage(1)
  }, [searchTerm])

  useEffect(() => {
    if (virtuosoRef.current) {
      virtuosoRef.current.scrollToIndex({ index: 0, align: "start" })
    }
  }, [currentPage])

  const handleSortChange = (
    key: "name" | "latest_assigned_date" | "latest_assigned_date_draft"
  ) => {
    if (sortBy === key) {
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"))
    } else {
      setSortBy(key)
      setSortOrder("desc")
    }
  }

  const startIndex = (currentPage - 1) * PAGE_SIZE + 1
  const endIndex = Math.min(currentPage * PAGE_SIZE, filteredData.length)
  const totalItems = filteredData.length
  const formatTimezone = (group: GroupData) => {
    return group.timezone
      ? `${group.timezone}${group.daylight_savings ? " DST" : ""}`
      : ""
  }

  const openGroupDetailModal = async (group: GroupData) => {
    try {
      const groupSettingsResponse = await api.getGroupSettings(group.groupid)
      const transformedSettings = transformToBooleans(groupSettingsResponse)
      const tableData = [
        {
          label: groupSettingsResponse.tigerconnect_organization_name,
          value: `Token: ${
            groupSettingsResponse.tigerconnect_organization || "N/A"
          }, Key: ${
            groupSettingsResponse.tigerconnect_key || "N/A"
          }, Integration ID: ${
            groupSettingsResponse.tigerconnect_integrationid || "N/A"
          }`,
          two_way_sync: groupSettingsResponse.tigerconnect_2way_sync || false,
        },
        ...(Array.isArray(groupSettingsResponse.tigerconnect_settings)
          ? groupSettingsResponse.tigerconnect_settings.map(
              (setting: TigerConnectSetting) => ({
                label: setting.label || "Unnamed Setting",
                value: `Token: ${setting.organization_token || "N/A"}, Key: ${
                  setting.tigerconnect_key || "N/A"
                }, Integration ID: ${setting.integrationid || "N/A"}`,
                two_way_sync: setting.two_way_sync || false,
              })
            )
          : []),
      ]
      setSelectedGroup(group)
      setGroupSettings(transformedSettings as GroupSettings)
      setOriginalGroupSettings(transformedSettings as GroupSettings)
      setEditedGroupName(group.name)
      setEditedGroupAbbrev(group.abbrev)
      setEditedTimezone(group.timezone)
      setDaylightSavings(group.daylight_savings ?? false)
      setShowDetailModal(true)
      setSettingsTableData(tableData as { label: string; value: string }[])
    } catch (error) {
      handleApiError(error as PlainObjectType)
    }
  }

  const handleSave = async () => {
    if (!selectedGroup || !groupSettings) return

    const groupId = selectedGroup.groupid
    const changes: Promise<any>[] = []
    setIsSaving(true)

    if (
      (editedGroupName && editedGroupName !== selectedGroup.name) ||
      (editedGroupAbbrev && editedGroupAbbrev !== selectedGroup.abbrev)
    ) {
      changes.push(
        api
          .updateGroupById(groupId, {
            name: editedGroupName,
            abbrev: editedGroupAbbrev,
          })
          .catch((error) => handleApiError(error))
      )
    }

    const updatedSettings = Object.keys(settingsMap).reduce((acc, key) => {
      const settingKey = key as keyof GroupSettings
      if (groupSettings[settingKey] !== originalGroupSettings?.[settingKey]) {
        acc[settingKey] = groupSettings[settingKey] ? "1" : "0"
      }
      acc.timezone = editedTimezone
      acc.daylight_savings = daylightSavings ? "1" : "0"
      return acc
    }, {} as Record<string, string>)

    if (Object.keys(updatedSettings).length > 0) {
      changes.push(
        api
          .updateGroupSettings(groupId, updatedSettings)
          .catch((error) => handleApiError(error))
      )
    }

    Object.entries(editingRowData).forEach(([index, rowData]) => {
      const idx = parseInt(index, 10)
      if (
        idx === 0 &&
        (rowData.two_way_sync !== groupSettings?.tigerconnect_2way_sync ||
          rowData.integrationid !== groupSettings?.tigerconnect_integrationid)
      ) {
        const payload = {
          id: "00000000-0000-0000-0000-000000000000",
          tigerconnect_2way_sync: rowData.two_way_sync ? "1" : "0",
          tigerconnect_integrationid: rowData.integrationid,
        }
        changes.push(
          api
            .updateGroupSettings(groupId, payload)
            .catch((error) => handleApiError(error))
        )
      } else if (
        idx !== 0 &&
        (rowData.integrationid || rowData.two_way_sync !== undefined)
      ) {
        const payload = {
          tigerconnect_settings: [
            {
              id: rowData.id,
              integrationid: rowData.integrationid,
              groupid: rowData.groupid,
              label: rowData.label,
              two_way_sync: rowData.two_way_sync,
              tigerconnect_key: rowData.tigerconnect_key,
              tigerconnect_secret: rowData.tigerconnect_secret,
              organization_token: rowData.organization_token,
              created_at: rowData.created_at,
              updated_at: new Date().toISOString(),
            },
          ],
        }
        changes.push(
          api
            .updateGroupSettings(groupId, payload)
            .catch((error) => handleApiError(error))
        )
      }
    })

    if (changes.length > 0) {
      try {
        await Promise.all(changes)
        await refreshClinicData()
        setShowDetailModal(false)
        setSelectedGroup(null)
      } catch (error) {
        handleApiError(error as PlainObjectType)
      }
    }

    setIsSaving(false)
    setEditingRowData({})
  }

  const handleEditRow = (index: number) => {
    setIsEditingRow(true)
    setEditingRowIndex(index)

    setEditingRowData((prev) => {
      const tigerConnectSettings = groupSettings?.tigerconnect_settings || []
      const initialData: TigerConnectSetting = {
        id: index === 0 ? "" : tigerConnectSettings[index - 1]?.id || "",
        groupid: selectedGroup?.groupid || 0,
        label:
          index === 0
            ? "Default Label"
            : tigerConnectSettings[index - 1]?.label || "",
        integrationid:
          index === 0
            ? String(groupSettings?.tigerconnect_integrationid || "")
            : String(tigerConnectSettings[index - 1]?.integrationid || ""),
        two_way_sync:
          index === 0
            ? ["true", "1"].includes(
                groupSettings?.tigerconnect_2way_sync?.toString() || ""
              )
            : tigerConnectSettings[index - 1]?.two_way_sync || false,
        tigerconnect_key:
          index === 0
            ? typeof groupSettings?.tigerconnect_key === "string"
              ? groupSettings?.tigerconnect_key
              : ""
            : tigerConnectSettings[index - 1]?.tigerconnect_key || "",
        tigerconnect_secret:
          index === 0
            ? typeof groupSettings?.tigerconnect_secret === "string"
              ? groupSettings?.tigerconnect_secret
              : ""
            : tigerConnectSettings[index - 1]?.tigerconnect_secret || "",
        organization_token:
          index === 0
            ? typeof groupSettings?.tigerconnect_organization === "string"
              ? groupSettings?.tigerconnect_organization
              : ""
            : tigerConnectSettings[index - 1]?.organization_token || "",
        created_at:
          index === 0
            ? typeof groupSettings?.created_at === "string"
              ? groupSettings.created_at
              : new Date().toISOString()
            : tigerConnectSettings[index - 1]?.created_at ||
              new Date().toISOString(),
        updated_at: new Date().toISOString(),
      }
      return {
        ...prev,
        [index]: initialData,
      }
    })
  }

  const modalTitle = (
    <div className={css.modalTitle}>
      <h4>{selectedGroup ? selectedGroup.name : "Group Detail"}</h4>
      <span>{selectedGroup ? selectedGroup.groupid : ""}</span>
    </div>
  )

  const modalSettingsTitle = (
    <div className={css.modalTitle}>
      <h4>Default Group Settings</h4>
    </div>
  )

  const modalAddGroupTitle = (
    <div className={css.modalTitle}>
      <h4>Add Group</h4>
    </div>
  )

  return (
    <div className={css.tableContainer}>
      <div className={css.controls}>
        <h2 className={css.clinicTitle}>Clinic: {clinic.name}</h2>
        <div>
          {showDefaultSettingsModal && <label>mostrar</label>}
          {isTrainer && (
            <button
              onClick={() =>
                setShowDefaultSettingsModal(!showDefaultSettingsModal)
              }
            >
              Default Settings
            </button>
          )}
          {canAddGroup && (
            <button onClick={() => setShowAddGroupModal(!showAddGroupModal)}>
              Add Group
            </button>
          )}
          <input
            type="text"
            placeholder="Search by group name or ID"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className={css.searchInput}
          />
        </div>
      </div>
      <TableVirtuoso
        ref={virtuosoRef}
        style={{ height: 600, width: "100%" }}
        data={pagedData}
        fixedHeaderContent={() => (
          <GroupManagementHeader
            sortBy={sortBy}
            sortOrder={sortOrder}
            handleSortChange={handleSortChange}
          />
        )}
        itemContent={(index, group) => (
          <tr key={index} className={css.groupManagementTable}>
            <td
              className={`${css.selectGroup}`}
              onClick={() => openGroupDetailModal(group)}
            >
              {group.groupid}
            </td>
            <td
              className={`${css.groupContainer} ${css.selectGroup}`}
              onClick={() => openGroupDetailModal(group)}
            >
              {group.name.length > 25 ? (
                <Tooltip title={group.name}>
                  <span>{group.name}</span>
                </Tooltip>
              ) : (
                <span>{group.name}</span>
              )}
            </td>
            <td className={css.groupContainer}>{group.abbrev}</td>
            <td>{group.jobs_count}</td>
            <td>{group.tc_integrated_jobs_count}</td>
            <td>{group.providers_count}</td>
            <td>{group.active_providers_count}</td>
            <td>{group.schedulers_count}</td>
            <td>{group.active_schedulers_count}</td>
            <td>{group.upcoming_assignments_count}</td>
            <td className={css.dateColumn}>{group.latest_assigned_date}</td>
            <td className={css.dateColumn}>
              {group.latest_assigned_date_draft}
            </td>
            <td>{group.provider_protocols_count}</td>
            <td>{group.protocols_count}</td>
            <td>{group.tigerconnect_roles_sync ? "Yes" : "No"}</td>
            <td>
              {group.scheduling_gap_notifications ? "Enabled" : "Disabled"}
            </td>
            <td>{formatTimezone(group)}</td>
          </tr>
        )}
      />
      <div className={css.paginationControls}>
        <button
          disabled={currentPage === 1}
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
        >
          Previous
        </button>
        <div className={css.paginationInfo}>
          <div>
            {startIndex}–{endIndex} of {totalItems}
          </div>
          <div>Page {currentPage}</div>
        </div>
        <button
          disabled={currentPage * PAGE_SIZE >= filteredData.length}
          onClick={() => setCurrentPage((prev) => prev + 1)}
        >
          Next
        </button>
      </div>
      <Modal
        title={modalSettingsTitle}
        show={showDefaultSettingsModal}
        className={css.modal}
        size="lg"
        onHide={() => {
          setShowDefaultSettingsModal(false)
        }}
      >
        <SettingsContentModal
          onClose={() => setShowDefaultSettingsModal(false)}
          clinicId={clinicid}
        />
      </Modal>
      <Modal
        title={modalAddGroupTitle}
        show={showAddGroupModal}
        className={css.modal}
        size="xl"
        onHide={() => {
          setShowAddGroupModal(false)
        }}
      >
        <AddGroupContentModal
          onClose={() => setShowAddGroupModal(false)}
          clinicid={clinicid}
          refreshClinicData={refreshClinicData}
        />
      </Modal>
      <Modal
        title={modalTitle}
        show={showDetailModal}
        className={css.modal}
        size="lg"
        onHide={() => {
          setShowDetailModal(false)
          setSelectedGroup(null)
          setEditingRowIndex(null)
          setIsEditingRow(false)
          setShowAddOrgModal(false)
        }}
      >
        {!showAddOrgModal && (
          <div className={css.modalContent}>
            <div className="row">
              <div className={cx(css.groupContainer, "col-md-6")}>
                <h3 className={css.label}>Group Name</h3>
                <input
                  className={css.groupNameInput}
                  type="text"
                  maxLength={80}
                  value={editedGroupName || ""}
                  onChange={(e) => setEditedGroupName(e.target.value)}
                />
              </div>
              <div className={cx(css.groupContainer, "col-md-6")}>
                <h3 className={css.label}>Group Abbreviation</h3>
                <input
                  className={css.groupNameInput}
                  type="text"
                  maxLength={20}
                  value={editedGroupAbbrev || ""}
                  onChange={(e) => setEditedGroupAbbrev(e.target.value)}
                />
              </div>
            </div>
            <div className={css.groupContainer}>
              <h3>Timezone</h3>
              <div className={css.timezoneSetting}>
                <TimezoneSetting
                  timezone={editedTimezone}
                  onChange={setEditedTimezone}
                />
                <label className={css.optionLabel}>
                  <input
                    type="checkbox"
                    className={css.checkbox}
                    checked={daylightSavings}
                    onChange={(e) => setDaylightSavings(e.target.checked)}
                  />
                  DST
                </label>
              </div>
            </div>
            <div className={css.groupContainer}>
              <h3 className={css.label}>Feature Settings</h3>
              <div className={css.groupSettingsCheckboxContainer}>
                <SettingsColumnsRenderer
                  groupid={selectedGroup?.groupid ?? 0}
                  settingsColumns={featureSettingsColumns}
                  groupSettings={groupSettings}
                  setGroupSettings={setGroupSettings}
                  canEditSettings={canEditSettings}
                  settingsMap={settingsMap}
                />
              </div>
            </div>
            <div className={css.groupContainer}>
              <h3 className={css.label}>TigerConnect Organization Settings</h3>
              <div className={css.groupSettingsCheckboxContainer}>
                <SettingsColumnsRenderer
                  groupid={selectedGroup?.groupid ?? 0}
                  settingsColumns={tigerConnectSettingsColumns}
                  groupSettings={groupSettings}
                  setGroupSettings={setGroupSettings}
                  canEditSettings={canEditSettings}
                  settingsMap={settingsMap}
                />
              </div>
            </div>
            <div className={css.groupContainer}>
              <div className="d-flex justify-content-end mr-4">
                <p
                  onClick={() => setShowAddOrgModal(true)}
                  className={css.addOrg}
                >
                  Add org
                </p>
              </div>
              <SyncSettingsTable
                settingsTableData={settingsTableData}
                editingRowIndex={editingRowIndex}
                editingRowData={editingRowData}
                setEditingRowIndex={setEditingRowIndex}
                setIsEditingRow={setIsEditingRow}
                setEditingRowData={setEditingRowData}
                handleEditRow={handleEditRow}
                canEditSettings={canEditSettings}
              />
            </div>
            <div className={css.buttonContainer}>
              <button
                className={css.cancelSettings}
                onClick={() => {
                  setEditingRowIndex(null)
                  setIsEditingRow(false)
                  setShowDetailModal(false)
                }}
                disabled={isSaving || isEditingRow}
              >
                Close
              </button>
              <button
                className={`${css.saveSettings}`}
                onClick={handleSave}
                disabled={isSaving || isEditingRow || !canEditSettings}
              >
                {isSaving ? "Saving..." : "Save"}
              </button>
            </div>
          </div>
        )}
        {showAddOrgModal && selectedGroup && (
          <AddOrgsToGroup
            clinicId={clinicid}
            groupId={selectedGroup?.groupid ?? 0}
            goBack={() => {
              setShowAddOrgModal(false)
              openGroupDetailModal(selectedGroup)
            }}
          />
        )}
      </Modal>
    </div>
  )
}

export default GroupManagementTable
