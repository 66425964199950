import React, { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "@app/models"
import Menu from "@app/containers/layouts/Menu"
import { Container } from "@app/components/Layout"
import { useIsAuthenticated } from "@app/utils/hooks"
import { useClinicPermission } from "@app/hooks"
import css from "./GroupManagement.module.scss"
import GroupManagementTable from "./GroupManagementTable/GroupManagementTable"
import Loader from "@app/components/Loader"

const GroupManagement = () => {
  const [isLoading, setIsLoading] = useState(true)
  const dispatch = useDispatch()
  const isAuthenticated = useIsAuthenticated()
  const canAccess = useClinicPermission("Group Admin")
  const { currentUser } = useSelector((state) => state.users)
  const isTrainer = currentUser?.current_role?.roleid === 5

  useEffect(() => {
    ;(async () => {
      await dispatch.users.getCurrentUserInfo()
    })()
  }, [])

  useEffect(() => {
    if (!isAuthenticated) return
    if (canAccess !== null && canAccess !== undefined) {
      const hasAccess = isTrainer || canAccess
      if (!hasAccess) {
        window.location.href = "/menu.cgi"
      } else {
        setIsLoading(false)
      }
    }
  }, [isAuthenticated, isTrainer, canAccess])

  return (
    <Fragment>
      <Menu userInfo={currentUser} />
      <Container size="fluid" className={css.groupManagementContainer}>
        {isLoading ? (
          <div className={css.loadingContainer}>
            <Loader animation="dots" />
          </div>
        ) : (
          <div>
            <GroupManagementTable />
          </div>
        )}
      </Container>
    </Fragment>
  )
}

export default GroupManagement
